import { logout } from '../redux/authSlice';
import { getStore, getThunkDispatch } from '../redux/store';
import { createNotification, isUndefined } from './common_utils';
import axios from 'axios';


const instance = axios.create({
    //baseURL: 'http://192.168.0.24:5000/'
    baseURL: 'https://test.be.rezervathor.com'
});


instance.interceptors.request.use(request => {
    const session = getStore().getState()?.auth;
    const token = session.token;
    const expirationDate = typeof session?.expirationDate === 'string' ? Date.parse(session?.expirationDate) : session?.expirationDate;
    
    if (expirationDate !== undefined && expirationDate < new Date()) {
        if (session.canShowLogoutNotif) createNotification("warning", "Vypršela platnost vaši relace. Pro pokračování se prosím znovu přihlašte.");
        getThunkDispatch()(logout());
    } else if (!isUndefined(token) && expirationDate !== undefined && expirationDate > new Date()) {
        request.headers.setAuthorization("Bearer " + token);
        //request.headers = {...request.headers, 'Authorization': "Bearer " + token}
    }
    return request;
});

instance.interceptors.response.use(response => {
    if (response.data.status === 'error') {
        createNotification(response.data.status, response.data.message, "Chyba aplikace");
        getThunkDispatch()(logout());
    }
    return response;
});

export default instance;