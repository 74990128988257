import { AuthDTO, AuthState } from '../types/securityTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEmplLogin } from './authActions';


const initialState: AuthState = {
    loading: false,
    isAdmin: false,
    token: undefined,
    error: undefined,
    employee: undefined,
    expirationDate: undefined,
    canShowLogoutNotif: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state: AuthState) => {
      state.loading = false;
      state.isAdmin = false;
      state.employee = undefined;
      state.token = undefined;
      state.error = undefined;
      state.canShowLogoutNotif = false;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getEmplLogin().pending, (state: AuthState) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(getEmplLogin().fulfilled, (state: AuthState, { payload }: PayloadAction<AuthDTO>) => {
      state.loading = false;
      state.error = undefined;
      state.employee = payload;
      state.isAdmin = payload.role === 'ADMIN';
      state.token = payload.token;
      state.expirationDate = new Date(new Date().getTime() + 86340000);
      state.canShowLogoutNotif = true;
    })
    .addCase(getEmplLogin().rejected, (state: AuthState, action) => {
      state.loading = false;
      state.error = action.payload as string || action.error.message || '';
    });
  },
});

export const { logout } = authSlice.actions
export default authSlice.reducer;
