import React from 'react';
import { notification, Badge, Space } from 'antd';
import dayjs from 'dayjs';
import { CreateTermDTO, TermDTO, TimeRangeDTO } from '../types/termsTypes';
import { AbstractAudit, ApplicationRole, ResponseStatus, Status } from '../types/common';
import type { Dayjs } from 'dayjs';
import { ReservationDTO, ReservationStatus } from '../types/reservationTypes';
import { EmployeeDTO } from '../types/employeeTypes';
import { AuthDTO } from '../types/securityTypes';
import { ServiceDTO } from '../types/serviceTypes';

const EMPTY_MESSAGE = React.createElement('span', {style: {color:'red'}}, 'Nevyplněno');
export const DATE_INFINITE = dayjs('3000-01-01');

/** COMMON UTILS */
export const isDateInFuture = (date?: Dayjs | Date | null) => {
    if (isUndefined(date)) return false;

    return dayjs(date) > dayjs();
}

export const isUndefined = (sth: any) => {
    return sth === undefined || sth === null || sth === '';
}

export const createNotification = (type: ResponseStatus, description: string, header?: string, duration?: number) => {
    notification[type]({
        duration: duration || 15,
        placement: 'bottomRight',
        message: header || 'Notifikace',
        description: description,
        showProgress: true,
        pauseOnHover: true
    });
}

export const getTextOrEmptyMessage = (sth: any, suffix?: string) => {
    return isUndefined(sth) ? EMPTY_MESSAGE : suffix ? sth + ' ' + suffix : sth;
}

/** BADGES UTILS */
export const getStatusBadge = (status: Status | undefined) => {
    return status === 'A'
        ? React.createElement(Badge, {color: 'green', status: 'processing', text: 'Aktivní'})
        : React.createElement(Badge, {color: 'red', text: 'Neaktivní'});
}

export const getStatusEmail = (verified: boolean | undefined) => {
    return verified
        ? React.createElement(Badge, {color: 'green', status: 'processing', text: 'Ověřený'})
        : React.createElement(Badge, {color: 'red', text: 'Neověřený'});
}

export const getReservationStatusBadge = (status: ReservationStatus | string | undefined) => {
    if (status === undefined) return;

    switch (status) {
        case 'A':
            return React.createElement(Badge, {color: 'green', status: 'processing', text: 'Potvrzená'});
        case 'N':
            return React.createElement(Badge, {color: 'orange', text: 'Nepotvrzená'});
        case 'D':
            return React.createElement(Badge, {color: 'red', text: 'Odmítnutá'});
        case 'C':
            return React.createElement(Badge, {color: 'red', text: 'Zrušená'});
        default:
            //X
            return React.createElement(Badge, {color: 'red', text: 'Nedostavil se'});
    }
}

export const getEmplName = (employee?: EmployeeDTO | AuthDTO) => {
    if (isUndefined(employee)) return EMPTY_MESSAGE;

    return isUndefined(employee?.nickname) ? (employee!.name + " " + employee!.surname) : employee!.nickname;
}

/** CONVERT FOR FE */
export const IconText = ({ icon, text }: { icon: React.FC; text: string }) => {
    return React.createElement(Space, {children: [React.createElement(icon), text]});
};

export const getRoleText = (role: ApplicationRole | undefined) => {
    return role === 'ADMIN' ? 'Admin' : 'Zaměstnanec';
}

export const getLastUpdateText = <T extends AbstractAudit>(data?: T): string => {
    if (isUndefined(data)) return getTextOrEmptyMessage(data);

    return isUndefined(data!.modified_by)
        ? data!.created_by + " v " + convertToFFDateTime(data!.created_date)
        : data!.modified_by + " v " + convertToFFDateTime(data!.modified_date);
};

export const convertToFFDateTime = (date?: Dayjs | Date | null) => {
    if (isUndefined(date)) return;

    return dayjs(date).format("DD. MM. YYYY - HH:mm");
}

export const convertToFFDate = (date?: Dayjs | Date | null) => {
    if (isUndefined(date)) return;

    const jsDate = dayjs(date);
    return jsDate.isSame(DATE_INFINITE) ? 'Nekonečno' : jsDate.format("DD. MM. YYYY");
}

export const convertReservationsIntoEvents = (data: ReservationDTO[]) => {
    return data.map(reservation => ({
        "id": reservation.id.toString(),
        "start": reservation.work_date + 'T' + reservation.time_from,
        "end": reservation.work_date + 'T' + reservation.time_to,
        "title": reservation.customer.name + " " + reservation.customer.surname,
        "groupId": reservation.price + ' Czk',
        "extendedProps": reservation})
    );
}

/** CONVERT TO DB */
export const convertToDbTime = (datetime: any) => {
    if (isUndefined(datetime)) return;

    return dayjs(datetime).format("HH:mm") + ":00";
}

export const convertToDBDate = (datetime: any) => {
    if (isUndefined(datetime)) return;
    
    return dayjs(datetime).format("YYYY-MM-DD");
}

export const validateMessages = () => {
    return {
        required: 'Tohle pole je povinné!',
        types: {
            email: 'Prosím, zadejte platný email!',
            number: 'Prosím, zadejte platné telefonní číslo.',
        },
        number: {
            range: "Číslo musí obsahovat 9 číslic.",
        },
    }
}

/*FOR TERM PAGE*/
export const removeMilis = (sth?: string) => {
    if (isUndefined(sth)) return;
    
    const ss = sth!.split(':');
    return ss[0] + ':' + ss[1];
}

export const convertTerms = (data: TermDTO[], selectedTerms: number[]) => {
    return data.map((term: TermDTO) => Object.create({
        id: term.id.toString(),
        start: term.working_date,
        title: removeMilis(term.time_from.toString()) + ' - ' + removeMilis(term.time_to.toString()),
        'extendedProps': {...term, 'selected': selectedTerms.includes(term.id)}
    }));
}

export const haveSameTerms = (arr: TermDTO[]) => {
    const timeCount:any = {};
    arr.forEach(item => {
      const key = `${item.time_from}-${item.time_to}`;
      if (!timeCount[key]) {
        timeCount[key] = 0;
      }
      timeCount[key]++;
    });
    
    for (const key in timeCount) {
      if (timeCount[key] >= 5) {
        return true;
      }
    }
    return false;
}

export const isTermPenetration = (dates: CreateTermDTO[], date: Dayjs, timeFrom: Dayjs, timeTo: Dayjs) => {
    const list = dates.filter(term =>
        convertToDBDate(date) === term.working_date && (
        (convertToDbTime(timeFrom)! >= term.time_from && convertToDbTime(timeFrom)! <= term.time_to) ||
        (convertToDbTime(timeTo)! >= term.time_from && convertToDbTime(timeTo)! <= term.time_to)));
    return list.length > 0;
}

export const createMapFromData = (data: CreateTermDTO[]): Record<string, TimeRangeDTO[]> => {
    return data.reduce((acc, item) => {
        const { working_date, time_from, time_to } = item;
        if (!acc[working_date]) {
            acc[working_date] = [];
        }
        acc[working_date].push({ time_from, time_to });
        return acc;
    }, {} as Record<string, TimeRangeDTO[]>);
};

export const convertReservationsToServiceList = (data: ReservationDTO[], all: boolean, employee_id?: number) => {
    if (isUndefined(data) || isUndefined(employee_id)) return [];
    
    const allServices: ServiceDTO[] = data
    .filter(s => all ? true : s.employee.id === employee_id)
    .flatMap(reservation => reservation.suir.map(suir => suir.service));
    const serviceCounts: Record<string, { id: number; name: string; count: number }> = allServices.reduce((acc, service) => {
        const key = `${service.id}-${service.name}`;
        if (!acc[key]) {
            acc[key] = { id: service.id, name: service.name, count: 0 };
        }
        acc[key].count += 1;
        return acc;
    }, {} as Record<string, { id: number; name: string; count: number }>);

    return Object.values(serviceCounts);
}